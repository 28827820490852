<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="serviceType">
          <el-select
              v-model="query.serviceType"
              clearable
              placeholder="请选择-业务类型"
              style="width: 260px"
          >
            <el-option label="停车订单" value="1"></el-option>
            <el-option label="长租订单" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="orderCode">
          <el-input
              v-model="query.orderCode"
              clearable
              placeholder="停车订单编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>

        <el-form-item prop="sourcePort">
          <el-select
              v-model="query.sourcePort"
              clearable
              placeholder="请选择-来源端"
              style="width: 260px"
          >
            <el-option label="IOS" value="1"></el-option>
            <el-option label="android" value="2"></el-option>
            <el-option label="巡检充值" value="3"></el-option>
            <el-option label="其他" value="4"></el-option>
            <el-option label="App端的H5" value="5"></el-option>
            <el-option label="商户端" value="6"></el-option>
            <el-option label="用户端" value="7"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="userTel">
          <el-input
              v-model="query.userTel"
              clearable
              placeholder="用户手机号码"
              style="width: 260px"
          ></el-input>
        </el-form-item>

        <el-form-item prop="autoId">
          <NumplateSuggest v-model="query.autoId" style="width: 260px"/>
          <!-- <el-input
            v-model="query.plateNumber"
            placeholder="车牌号码搜索"
            clearable
            style="width: 260px"
          ></el-input> -->
        </el-form-item>

        <el-form-item prop="parkingLotId">
          <SuggestParking v-model="query.parkingLotId" style="width: 260px"/>
          <!-- <el-input
            v-model="query.parkingLotName"
            placeholder="停车场名称搜索"
            clearable
            style="width: 260px"
          ></el-input> -->
        </el-form-item>

        <!-- <el-form-item prop="merchantOrderNo">
          <el-input
            v-model="query.merchantOrderNo"
            placeholder="请输入商户单号"
            prefix-icon=""
            clearable
            style="width: 260px"
          ></el-input>
        </el-form-item> -->

        <el-form-item prop="outThirdNo">
          <el-input
              v-model="query.outThirdNo"
              clearable
              placeholder="第三方流水号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>

        <el-form-item prop="tradeType">
          <el-select
              v-model="query.tradeType"
              clearable
              placeholder="请选择-交易类型"
              style="width: 260px"
          >
            <el-option label="支付" value="1"></el-option>
            <el-option label="退款" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="executeStatus">
          <el-select
              v-model="query.executeStatus"
              clearable
              placeholder="请选择-执行状态"
              style="width: 260px"
          >
            <el-option label="成功" value="1"></el-option>
            <el-option label="失败" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="searchDate">
          <el-date-picker
              v-model="query.searchDate"
              end-placeholder="结束日期"
              prefix-icon="none"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="业务类型" prop="serviceType" width="140">
          <template slot-scope="scope">
            <span>{{ serviceType[scope.row.serviceType] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderCode" width="240">
        </el-table-column>
        <el-table-column label="来源端" prop="sourcePort" width="180">
          <template slot-scope="scope">
            <span>{{ sourcePort[scope.row.sourcePort] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="userTel" width="180">
        </el-table-column>
        <el-table-column label="车辆" prop="plateNumber" width="180">
          <template slot-scope="scope">
            <Numplate :type="scope.row.plateColor">{{
                scope.row.plateNumber
              }}
            </Numplate>
          </template>
        </el-table-column>
        <el-table-column label="车场名称" prop="parkingLotName" width="240">
        </el-table-column>
        <el-table-column label="交易类型" prop="tradeType" width="140">
          <template slot-scope="scope">
            {{ tradeType[scope.row.tradeType] }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="支付金额(元)"
            prop="paymentAmount"
            width="140"
        >
          <template slot-scope="scope">
            {{ parseFloat(scope.row.paymentAmount).toFixed(2) }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="merchantOrderNo"
          label="商户单号"
          align="center"
          width="180"
        >
        </el-table-column> -->
        <el-table-column label="第三方流水号" prop="outThirdNo" width="300">
        </el-table-column>
        <el-table-column label="创建时间" prop="createDate" width="180">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createDate) }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="payDate" label="支付时间" width="180">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.payDate) }}
          </template>
        </el-table-column> -->
        <el-table-column label="执行状态" prop="executeStatus" width="180">
          <template slot-scope="scope">
            {{ executeStatus[scope.row.executeStatus] }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            fixed="right"
            label="操作"
            prop="name"
            width="100px"
        >
          <template slot-scope="scope">
            <el-button
                size="small"
                style="font-size: 14px"
                type="text"
                @click="goDetail(scope.row.id)"
            >明细
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.page"
            :page-size="pagination.size"
            :page-sizes="[10, 15, 30, 50]"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 明细弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="detailDialogFormVisible"
        class="common-dialog"
        title="明细"
        width="1400px"
    >
      <el-table
          v-loading="isDetailLoading"
          :data="detailList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column align="center" label="节点类型" prop="node">
        </el-table-column>
        <el-table-column align="center" label="节点详情" prop="nodeDetail">
        </el-table-column>
        <el-table-column
            align="center"
            label="执行结果"
            prop="excuteResult"
            width="180"
        >
        </el-table-column>
        <el-table-column align="center" label="失败原因" prop="faildReason">
        </el-table-column>
        <el-table-column align="center" label="时间" prop="time">
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closed">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import NumplateSuggest from "@/components/NumplateSuggest";
import SuggestParking from "@/components/SuggestParking";
import Numplate from "@/components/Numplate";
import {paylogdetail, searchpaylog} from "@/api/finance";
import {doExport} from "@/api/common";

export default {
  name: "pay_log",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      //导出
      form: {
        exportSize: 1,
      },
      query: {
        // 业务类型 停车业务=1 长租业务=2 预约业务=3 充电业务=4 充值业务=5
        serviceType: "",
        // 订单编号（模糊搜索）
        orderCode: "",
        // 来源端 pos=0 静态码=1
        sourcePort: "",
        // 手机号码(模糊搜索)
        userTel: "",
        // 车辆号码（模糊）
        plateNumber: "",
        // 停车场名称（模糊）
        parkingLotName: "",
        parkingLotId: "",
        // 商户单号（模糊）
        // merchantOrderNo: "",
        // 第三方流水号(模糊)
        outThirdNo: "",
        // 交易类型 支付=1 退款=2
        tradeType: "",
        // 执行状态 成功=1 失败=2
        executeStatus: "",
        // 发起时间开始
        startTime: "",
        // 发起时间结束
        endTime: "",
        searchDate: [],
        plateColor: "",
        autoId: "",
        isExport: 0,
      },
      serviceType: {
        CLOSED_PARKING: "停车订单",
        LEASE_114514: "长租订单",
      },
      sourcePort: {
        1: "IOS",
        2: "android",
        3: "巡检充值",
        4: "其他",
        5: "app端的H5",
        6: "商户端",
        7: "用户端",
      },
      tradeType: {
        1: "支付",
        2: "退款",
      },
      executeStatus: {
        1: "成功",
        2: "失败",
      },
      dataList: [],
      isLoading: false,
      detailList: [],
      isDetailLoading: false,
      detailDialogFormVisible: false, //明细弹窗
      exportDialogFormVisible: false, //导出弹窗
    };
  },
  components: {
    NumplateSuggest,
    SuggestParking,
    Numplate,
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      const params = this.paramFormat(this.query);
      if (params.searchDate.length > 0) {
        params.startTime = params.searchDate[0] + " 00:00:00";
        params.endTime = params.searchDate[1] + " 23:59:59";
      }
      this.isLoading = true;
      const res = await searchpaylog(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        if (res.returnObject === null) {
          this.dataList = [];
        } else {
          const returnObject = res.returnObject;
          this.dataList = returnObject.list;
          this.pagination.totalSize = returnObject.total;
          this.pagination.totalPages = returnObject.pages;
          this.form.exportSize = returnObject.total;
        }
      }
    },
    //查看明细
    goDetail(e) {
      this.detailDialogFormVisible = true;
      this.isDetailLoading = true;
      paylogdetail(e).then((res) => {
        this.isDetailLoading = false;
        if (res && res.code === 30 && res.result) {
          this.detailList = res.returnObject;
        }
      });
    },
    //关闭明细弹窗
    closed() {
      this.detailDialogFormVisible = false;
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const params = this.paramFormat(this.query);
      if (params.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport("/pay/searchpaylog", params, "支付日志.xlsx");
      // window.open(
      //   window.location.protocol +
      //     "//" +
      //     window.location.host +
      //     "/opapi/pay/searchpaylog?" +
      //     objectToQueryString(params)
      // );

      this.exportDialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
